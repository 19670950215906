import React, { useCallback, useState, useEffect } from 'react';

import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { AppInputNumber } from 'src/app/components/form/input-number';
import { roundTo } from 'src/app/helpers/strings';
import { selectUserData, selectModel, setInteractions, setModel } from 'src/app/slices/financialModelSlice';

import styles from './ModelTimeHorizonModal.module.css';

export const ModelTimeHorizon = ({ isVisible, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector(selectUserData);
  const selectedModel = useSelector(selectModel);

  const [investmentTime, setInvestmentTime] = useState();
  const [buybacksValue, setBuybacksValue] = useState();
  const [value, setValue] = useState();
  useEffect(() => {
    setInvestmentTime(userData?.time_horizon);
    setValue(userData?.time_horizon);
    setBuybacksValue(roundTo(userData?.target_buyback_price_book, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const handleChangeInvestmentTime = useCallback((value) => {
    const editedValue = Math.round(value);
    setValue(value);
    setInvestmentTime(editedValue);
  }, []);

  const handleUpdate = useCallback(() => {
    if (investmentTime && investmentTime !== userData?.time_horizon) {
      dispatch(setModel({ ...selectedModel, user_data: { ...userData, target_buyback_price_book: buybacksValue } }));
      dispatch(
        setInteractions({
          time_horizon: investmentTime
        })
      );
    } else {
      dispatch(setModel({ ...selectedModel, user_data: { ...userData, target_buyback_price_book: buybacksValue } }));
    }
    handleClose();
  }, [dispatch, handleClose, investmentTime, buybacksValue, userData, selectedModel]);

  return (
    <Modal
      title={t('model_time_modal_title')}
      centered
      width={600}
      visible={isVisible}
      className={`mg-modal`}
      okText={t('update_button_label')}
      cancelText={t('cancel')}
      getContainer={'main'}
      okButtonProps={
        value > 1 && value < 21
          ? {
              className: `mg-btn ${styles.button}`
            }
          : {
              className: `mg-btn disabled ${styles.button}`
            }
      }
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`
      }}
      onOk={handleUpdate}
      onCancel={handleClose}
    >
      <table>
        <tbody className={styles.parametersInputWrapper}>
          <tr>
            <td>{t('investment_time_horizon_label')}</td>
            <td>
              <AppInputNumber value={investmentTime} onChange={handleChangeInvestmentTime}></AppInputNumber>
            </td>
          </tr>
          <tr>
            <td>{t('buyback_value_label')}</td>
            <td>
              <div>
                <AppInputNumber value={buybacksValue} onChange={setBuybacksValue} min={0} max={10000}></AppInputNumber>
              </div>
            </td>
            <td>book value</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};
