import React, { useCallback, useEffect, useState } from 'react';

import { Anchor, Button, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { loadAlertsListByViewAsync, loadAlertTypesAsync } from 'src/app/api/alerts';
import { saveModelAsync, interactModelAsync } from 'src/app/api/financial-model';
import { addToIdeasAsync } from 'src/app/api/ideas';
// import { RestrictionModal } from 'src/app/components/restriction-modal';
import { InsiderCompanyAlertModel, PriceCompanyAlertModel } from 'src/app/models/alerts';
import { SetAlert } from 'src/app/modules/account-alerts/components/set-alert-modal/index';
import { alertView } from 'src/app/modules/account-alerts/consts';
import { definePersonPosition } from 'src/app/modules/account-alerts/helpers';
import {
  selectInsidersAlertsTotal,
  selectPriceAlertsTotal,
  setAlerts,
  setInsidersAlerts,
  setPriceCompanyAlerts
} from 'src/app/slices/alertsSlice';
import { selectCompany } from 'src/app/slices/companySlice';
import {
  selectUserData,
  selectSavedUserData,
  setSavedUserData,
  selectSavedDepth,
  setModel,
  setInteractions,
  setMessage
} from 'src/app/slices/financialModelSlice';
import { selectProfile } from 'src/app/slices/profileSlice';
import addAlertIcon from 'src/assets/svg/company/company-add-alert.svg';
import { ReactComponent as MenuIcon } from 'src/assets/svg/menu.svg';

import styles from './CompanyHeader.module.css';
import { CalculationParameters } from './components/calculation-parameters-modal';
import { ModelTimeHorizon } from './components/model-time-horizom-modal';
import { SelectAlgorithm } from './components/select-algorithm-modal';
import { SensitivityModal } from './components/sensitivity-modal';
import { TutorialModal } from './components/tutorial-modal';
import { menuItemsArray, modelMenuItems } from './constants';
import './style.css';

const { Link } = Anchor;

export const Header = ({ currentView, handleError, advanced }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { id } = useParams();

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [isCalculationModalOpen, setIsCalculationModalOpen] = useState(false);
  const [isAlgorithmModalOpen, setIsAlgorithmModalOpen] = useState(false);
  const [isModelModalOpen, setIsModelModalOpen] = useState(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [sensitivityModalOpen, setSensitivityModalOpen] = useState(false);

  const company = useSelector(selectCompany);
  const profile = useSelector(selectProfile);
  const insidersAlertsTotal = useSelector(selectInsidersAlertsTotal);
  const pricesAlertsTotal = useSelector(selectPriceAlertsTotal);
  const userData = useSelector(selectUserData);
  const savedUserData = useSelector(selectSavedUserData);
  const savedDepth = useSelector(selectSavedDepth);

  const [selectedAnchor, setSelectedAnchor] = useState('');

  const handleAnchor = useCallback((e, link) => {
    e.preventDefault();
    setSelectedAnchor(link.href.slice(1));
  }, []);

  const handleOpenModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleAddToIdeas = useCallback(() => {
    // @ts-ignore
    dispatch(addToIdeasAsync(company.tickerId)).catch((e) => {
      handleError(e);
    });
  }, [dispatch, company, handleError]);

  const handleOpenControlModal = useCallback((modalKey) => {
    switch (modalKey) {
      case 'tutorial':
        setTutorialModalOpen(true);
        break;
      case 'fair_value':
        setIsCalculationModalOpen(true);
        break;
      case 'model_builder':
        setIsAlgorithmModalOpen(true);
        break;
      case 'sensitivity':
        setSensitivityModalOpen(true);
        break;
      default:
        setIsModelModalOpen(true);
    }
  }, []);

  const closeVisible = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(loadAlertTypesAsync())
      // @ts-ignore
      .then((data) => {
        dispatch(setAlerts(data));
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, handleError]);

  const loadAlertsPrices = useCallback(() => {
    dispatch(loadAlertsListByViewAsync(alertView.price, 0, 50, { column: 'companyName', direction: 'ASC' }))
      // @ts-ignore
      .then((data) => {
        const res = [...data.items.map((elem) => new PriceCompanyAlertModel(elem))];
        dispatch(setPriceCompanyAlerts({ alerts: res, total: data.totalAlerts }));
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, handleError]);

  const loadAlertInsiders = useCallback(() => {
    dispatch(loadAlertsListByViewAsync(alertView.insider, 0, 50, { column: 'companyName', direction: 'ASC' }))
      // @ts-ignore
      .then((data) => {
        const res = [
          ...data.items.map(
            (elem) =>
              new InsiderCompanyAlertModel({
                ...elem,
                personPosition: i18n.t(definePersonPosition(elem.is_director, elem.is_officer))
              })
          )
        ];
        dispatch(setInsidersAlerts({ alerts: res, total: data.totalAlerts }));
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, i18n, handleError]);

  useEffect(() => {
    loadAlertInsiders();
    loadAlertsPrices();
  }, [loadAlertInsiders, loadAlertsPrices, visible]);

  const getTotalAlerts = useCallback(() => {
    if (profile?.restriction?.data?.alertCreationLimit === null) {
      return Infinity;
    }
    return profile?.restriction?.data?.alertCreationLimit;
  }, [profile?.restriction?.data?.alertCreationLimit]);

  const onScroll = useCallback(() => {
    menuItemsArray.forEach((item) => {
      const element = document.getElementById(item.key);
      if (window.pageYOffset >= element?.offsetTop - 100) {
        setSelectedAnchor(item.key);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const handleReloadModel = useCallback(() => {
    dispatch(
      interactModelAsync({
        interactions: {
          depth: savedDepth || 4
        },
        user_data: {
          cik: id,
          ...savedUserData?.value
        }
      })
    )
      // @ts-ignore
      .then((model) => {
        dispatch(setInteractions(null));
        dispatch(setModel(model));
        dispatch(setMessage(model.interactions));
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, id, savedDepth, savedUserData, handleError]);

  const handleSaveModel = useCallback(() => {
    if (userData) {
      dispatch(saveModelAsync(userData))
        .then((data) => {
          dispatch(setSavedUserData(data));
        })
        .catch((e) => {
          handleError(e);
        });
    }
  }, [dispatch, userData, handleError]);

  const navigateToAdvanced = useCallback(() => {
    navigate(`/company/overview/${id}/advanced`);
  }, [navigate, id]);

  return (
    <>
      <div className={advanced ? `company-nav ${styles.advancedWrapMenu}` : `company-nav ${styles.wrapMenu}`}>
        <Menu className={styles.menu} mode="horizontal" overflowedIndicator={<MenuIcon />} triggerSubMenuAction="click">
          {currentView === 'info'
            ? menuItemsArray.map((item) => (
                <Menu.Item className={styles.menuItem} key={item.key}>
                  <Anchor onClick={handleAnchor} offsetTop={115}>
                    <Link
                      href={`#${item.anchor}`}
                      title={t(`${item.titleKey}`)}
                      className={selectedAnchor === item.key ? styles.selectedAnchor : ''}
                    />
                  </Anchor>
                </Menu.Item>
              ))
            : modelMenuItems.map((item) =>
                profile?.role?.title === 'Administrator' || item.key !== 'sensitivity' ? (
                  <Menu.Item
                    className={item.key === 'tutorial' ? styles.tutorial : styles.menuItem}
                    key={item.key}
                    onClick={() => handleOpenControlModal(item.key)}
                  >
                    {t(`${item.titleKey}`)}
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    className={styles.menuItem}
                    key={item.key}
                    onClick={() => handleOpenControlModal(item.key)}
                  ></Menu.Item>
                )
              )}
        </Menu>
        {currentView === 'info' ? (
          <>
            <Button className={`mg-btn _text ${styles.addAlertBtn}`} onClick={handleOpenModal}>
              <img src={addAlertIcon} />
              <span>{t('menu_company_add_alert')}</span>
            </Button>
            <Button className={`mg-btn _text ${styles.addAlertBtn}`} onClick={handleAddToIdeas}>
              <img src={addAlertIcon} />
              <span>{t('add_to_ideas')}</span>
            </Button>
          </>
        ) : (
          <div className={styles.actionsWrapper}>
            <span onClick={handleReloadModel}>{t('reload_model_label')}</span>
            <span onClick={handleSaveModel}>{t('save_model_label')}</span>
            <span onClick={navigateToAdvanced}>Advanced</span>
          </div>
        )}
      </div>
      <SetAlert
        loadAlerts={closeVisible}
        visible={visible}
        companyName={company?.companyName}
        companyCik={company?.cik}
        defaultActiveKey={1}
        alertsNumber={getTotalAlerts() - insidersAlertsTotal - pricesAlertsTotal}
        handleError={handleError}
      />
      {/* )} */}
      <CalculationParameters
        isVisible={isCalculationModalOpen}
        handleError={handleError}
        handleClose={() => setIsCalculationModalOpen(false)}
      />
      <SelectAlgorithm
        isVisible={isAlgorithmModalOpen}
        handleClose={() => setIsAlgorithmModalOpen(false)}
        handleError={handleError}
      />
      <TutorialModal visible={tutorialModalOpen} onCancel={() => setTutorialModalOpen(false)} />
      <SensitivityModal
        handleError={handleError}
        visible={sensitivityModalOpen}
        onCancel={() => setSensitivityModalOpen(false)}
      />
      <ModelTimeHorizon isVisible={isModelModalOpen} handleClose={() => setIsModelModalOpen(false)} />
    </>
  );
};
